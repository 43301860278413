<template>
  <div v-loading="loading" class="index">
    <div class="welcome">{{ userInfo.nickname || '无昵称' }}，欢迎您登录到会员中心！</div>
    <div v-if="userInfo.loginTime" class="preLogin">上次登录时间为 {{ userInfo.loginTime }}</div>
    <div class="bottomInfo" :style="{visibility: loading ? 'hidden':''}">
      <div v-for="(item,index) in personalInfo" :key="item.key" :style="{ marginTop: index > 3 ? '24px' : '0' }" class="item">
        <div class="value">{{ item.value }}</div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      personalInfo: [
        { name: '群众随手拍数', key: 'readilyCount', value: 0 },
        { name: '留言建议数', key: 'proposalCount', value: 0 },
        { name: '对话书记数', key: 'secretaryCount', value: 0 },
        { name: '在线咨询数', key: 'onlineCount', value: 0 },
        { name: '需求对接数', key: 'demandCount', value: 0 },
        { name: '困难帮扶数', key: 'diffPersonCount', value: 0 },
        { name: '监督举报数', key: 'superviseCount', value: 0 },
        { name: '信息监管数', key: 'msgSuperviseCount', value: 0 }
      ],
      loading: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  watch: {
    $route() {
      this.getInfo()
    }
  },

  mounted() {

  },
  created() {
    this.getInfo()
  },

  methods: {
    getInfo() {
      this.loading = true
      this.$store.dispatch('login/AccountGetUserReport').then(res => {
        if (res.code === 0) {
          this.personalInfo.forEach((item, index) => {
            for (const key of Object.keys(res.data)) {
              const value = res.data[key]
              if (item.key === key) {
                item.value = value
                break
              }
            }
          })
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .index{
    margin-top: 40px;
    min-height:487px;
    .welcome{
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .preLogin{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      margin-top: 20px;
    }
    .bottomInfo{
      margin: 81px 0 0 18px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 1032px;
      .item{
        width: 234px;
        height: 156px;
        position: relative;
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--theme);
        line-height: 45px;
        .value{
          font-size: 30px;
        }
        .name{
          font-size: 20px;
        }
        &::after{
          content:'';
          background: var(--theme);
          opacity: .1;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          position: absolute;
          z-index: -1;
        }
      }
    }
  }
</style>
